// ... your code that runs before foundation initiation

// Fix for foundation stylesheets being picked up as "null" or "not an object",
// implementation from here: http://foundation.zurb.com/forum/posts/3189-foundation-5-orbit-slider-ie8-issue
// (function($) {
//     'use strict';

//     if (!Foundation.stylesheet) {
//         Foundation._style_element = $('<style></style>').appendTo('head')[0];
//         Foundation.stylesheet = Foundation._style_element.styleSheet;

//         if (Foundation.stylesheet) {
//             Foundation.stylesheet.cssRules = {
//                 length: 0
//             };

//             Foundation.stylesheet.insertRule = function(rule, index) {
//                 var media;
//                 var mediaMatch;
//                 var mediaRegex;
//                 var namespace;
//                 var ruleMatch;
//                 var ruleRegex;
//                 mediaRegex = /^\s*@media\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 mediaMatch = mediaRegex.exec(rule);
//                 media = '';

//                 if (mediaMatch) {
//                     media = '@media ' + mediaMatch[1] + ' ';
//                     rule = mediaMatch[2];
//                 }

//                 ruleRegex = /^\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 ruleMatch = ruleRegex.exec(rule);
//                 namespace = '' + media + ruleMatch[1];
//                 rule = ruleMatch[2];

//                 return this.addRule(namespace, rule);
//             };
//         } else if (window.console) {
//             console.log('Could not fix Foundation CSS rules...');
//         }
//     }
// })(jQuery);

// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

function HCTest() {
    'use strict';

    var objDiv;
    var strColor;
    var objFlag;

    // reference to img element used to check if images are disabled
    objFlag = document.getElementById('flag');
    if (objFlag === null) {
        return;
    }

    // Create a test div
    objDiv = document.createElement('div');

    // Set its color style to something unusual
    objDiv.style.color = 'rgb(31,41,59)';

    // Attach to body so we can inspect it
    document.body.appendChild(objDiv);

    // Use standard means if available, otherwise use the IE methods

    strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

    // get rid of extra spaces in result
    // strColor = strColor.replace(/ /g,"");

    // Delete the test DIV

    document.body.removeChild(objDiv);

    // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
    // Use .offsetwidth and .readyState (for IE) to check if images are enabled
    // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

    if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

        var objHead = document.getElementsByTagName('head');
        var objCSS = objHead[0].appendChild(document.createElement('link'));
        objCSS.rel = 'stylesheet';
        objCSS.href = '/media/css/alt.css';
        // objCSS.type = 'text/css';
    }
}

// Safely initiate foundation now

//$(document).foundation();

// the rest of your code

$(window).load(function() {
    'use strict';

    // accessible image sprites
    HCTest();
});

// mobile-nav
(function() {

    var mqm = window.matchMedia('(max-width: 64.0625em)');

    // get the button and menu nodes
    var button = document.querySelector('[aria-label="site"] button');
    if (button != null) {
        var menu = button.nextElementSibling;

        // set initial (closed menu) states
        button.setAttribute('aria-expanded', 'false');
        button.hidden = false;
        if (mqm.matches) {
            menu.hidden = true;
        }

        $( window ).resize(function() {
            if (window.matchMedia('(min-width: 620px)').matches) {
                menu.hidden = false;
            }
        });

        button.addEventListener('click', function() {
            // toggle menu visibility
            var expanded = this.getAttribute('aria-expanded') === 'true';
            this.setAttribute('aria-expanded', String(!expanded));
            menu.hidden = expanded;
        });
    }

})();

$(document).ready(function() {
    'use strict';

    //cookies
    $('.ihavecookies_change').click(function(event){
        event.preventDefault();
        $('body').ihavecookies(options, 'reinit');
    });

    // feature.js
    if (feature.svg) {
        $('html').addClass('svg');
    }

   

    // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
    // bind a click event to the 'skip' link
    $(".skip-link").click(function(event){

        // strip the leading hash and declare
        // the content we're skipping to
        var skipTo="#"+this.href.split('#')[1];

        // Setting 'tabindex' to -1 takes an element out of normal
        // tab flow but allows it to be focused via javascript
        $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

            // when focus leaves this element,
            // remove the tabindex attribute
            $(this).removeAttr('tabindex');

        }).focus(); // focus on the content container
    });

    // Match Height
    /*$('.locations div').matchHeight();
    $('.maratons__wrapper').matchHeight();
    $('.collab-box').matchHeight();
    $('.text-wrapper').matchHeight();*/

    // canvi svg per png
    if (!feature.svg) {
        var imgs = document.getElementsByTagName('img');
        var endsWithDotSvg = /.*\.svg$/;
        var i = 0;
        var l = imgs.length;
        for (; i !== l; ++i) {
            if (imgs[i].src.match(endsWithDotSvg)) {
                imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
            }
        }
    }

    // smooth scroll top
    $(window).scroll(function() {
      if ($(this).scrollTop() > 300) {
          $(".top").show();
          $(".menu-fixed").addClass('open');
      } else {
          $(".top").hide();
          $(".menu-fixed").removeClass('open');
      }
    });
    $(function() {
      $('a[href*="#"]:not([href="#"],[href="#s"],[href^="#hospital_"],[href^="#lloc"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    });

  

    // alert
    var alertBtn = $('.alert').children('button');
    alertBtn.on('click', function() {
      var state = $(this).attr('aria-expanded') === 'false' ? true : false;
      $(this).attr('aria-expanded', state);
      $(this).parent().toggleClass("js-closed");
      var id = $(this).data('id');
      // Manage Save event
      if(sessionStorage){
        sessionStorage.setItem("alertaVista"+id, "1");
      }
    });
    if($("#alert").length > 0){
      var id = $('#alert').data('id');
      if (sessionStorage.getItem("alertaVista"+id)) {
          $('.alert').css('display','none');
      }
    }

    //test
    function auto_load(){
        var formData = $("#myForm").serialize();
        $.ajax({
          url: urlTest,
          cache: false,
          data: formData,
          type: "POST",
          success: function(data){
            var pas = $("#pregunta").val();
             if($("#properaPregunta").length > 0){
                $("#pregunta-num").text($("#properaPregunta").val());
             }
             $("#page").html(data);
             var src = $("#pas"+pas).attr('src');
             $("#pas"+pas).attr('src', src.replace('negre', 'blanc'));
             $("#pas"+pas).addClass('completat');
             $("#pas"+pas).addClass('carregat');
          }
        });

    }
    if($(".test-container").length > 0){
      $(document).on('submit','.test-container form', function( event ) {
               event.preventDefault();

              $( "form" ).removeClass('navInNext').addClass('navOutNext');
              setTimeout(function(){
                  auto_load();
              }, 750);
              return false;
      });
      $(document).on('change','form input[type=radio]', function( event ) {
             $( "#myForm" ).trigger( "submit" );
      });
      $('#start').on('click', function() {
         $('#siteloader').load(urlTest);
         $('#test-rapid').addClass('test-container--move');
           $("html, body").animate({ scrollTop: 0 }, 1000);
          return false;

      });
      $('#tancar-joc').on('click', function() {
         $('#test-rapid').removeClass('test-container--move');
         return false;
      });
    }



    
    //fer clicables els títols dels llistats recollides
    $(".locations h4,.locations h3").click(function(){
      var url = $(this).parent().find("a.more").attr("href");
      if(url != undefined){
        window.location= url;
      }
      return false;
    });
    $("span.lloc").click(function(){
        var url = $(this).parent().parent().find("a.more").attr("href");
        if(url != undefined){
          window.location= url;
        }
        return false;
    });
    $(".locations h4,.locations span.lloc,.locations h3").addClass("hover");
    


    if ($('body.inscdonacions form.hospital').length > 0) {
        $('body.inscdonacions form.hospital').find('select[name="hospital"]').not('.iphone').select2();
        function inscSubmit() {
            var hospital = $('body.inscdonacions form.hospital').find('select[name="hospital"]').val();
            var day = $('#hospital_day').val();
            var hora = $('#hospital_hour').val();

            if ((hospital == '' || hospital == undefined) ||
                (day == '' || day == undefined) ||
                (hora == '' || hora == undefined)) {
                $('body.inscdonacions form.hospital button').hide();
            } else {
                $('body.inscdonacions form.hospital button').show();
            }
        }
        $('body.inscdonacions form.hospital button').hide();
        /*$('body.inscdonacions form.hospital select[name="hospital"]').on('change', function(event){
            event.preventDefault();

            var extimageLoading = 'gif';
            //if (feature.svg) {
            //    extimageLoading = 'svg';
            //}
            $("body.inscdonacions form.hospital").prepend('<div class="loading"><img src="/media/img/loading-ajax.'+ extimageLoading +'" alt="Carregant..."></div>');

            var rowCalendar = $('body.inscdonacions form.hospital .calendarRow');
            var hospital = $('body.inscdonacions form.hospital').find('select[name="hospital"]').val();
            $.get("./", { hospital: hospital}, function(data) {
                rowCalendar.html(data);
                $("body.inscdonacions form.hospital .loading").remove();
                inscSubmit();
            });
            return false;
        });*/
        $('body.inscdonacions form.hospital').on('click', '.calendari thead a', function(event){
            event.preventDefault();

            var extimageLoading = 'gif';
            //if (feature.svg) {
            //    extimageLoading = 'svg';
            //}
            $("body.inscdonacions form.hospital").prepend('<div class="loading"><img src="/media/img/loading-ajax.'+ extimageLoading +'" alt="Carregant..."></div>');

            var calendar = $(this).closest('div');
            var month = $(this).data('month');
            var day = $('#hospital_day').val();
            //var hospital = $('body.inscdonacions form.hospital').find('select[name="hospital"]').val();
            var hospital = $('body.inscdonacions form.hospital').find('input[name="hospital"]').val();
            $.get( "../calendar.php", { hospital: hospital, month: month, day: day }, function(data) {
                calendar.html(data);
                $("body.inscdonacions form.hospital .loading").remove();
                inscSubmit();
            });
            return false;
        });
        $('body.inscdonacions form.hospital').on('click', '.calendari tbody a', function(event){
            event.preventDefault();

            var extimageLoading = 'gif';
            //if (feature.svg) {
            //    extimageLoading = 'svg';
            //}
            $("body.inscdonacions form.hospital").prepend('<div class="loading"><img src="/media/img/loading-ajax.'+ extimageLoading +'" alt="Carregant..."></div>');

            var rowCalendar = $('body.inscdonacions form.hospital .calendarRow');
            //var hospital = $('body.inscdonacions form.hospital').find('select[name="hospital"]').val();
            var hospital = $('body.inscdonacions form.hospital').find('input[name="hospital"]').val();
            var day = $(this).data('time');
            $.get( "../", { hospital: hospital, day: day }, function(data) {
                rowCalendar.html(data);

                $("body.inscdonacions form.hospital .loading").remove();
                inscSubmit();
            });
            return false;
        });
        $('body.inscdonacions form.hospital').on('change', '#hospital_hour', function(event){
            inscSubmit();
            return false;
        });
    }
    $( '#legallabel').click(function(event) {
            event.preventDefault();
            $('#legalhover').show();
        });
    if ($('body.inscdonacionshospitals form.reserva').length > 0) {

        


        if (
            $('body.inscdonacionshospitals form.reserva .dades-hospital').length == 0 &&
            $('body.inscdonacionshospitals form.reserva select[name="hospital"] option:selected').length > 0) {
            $("body.inscdonacionshospitals form.reserva").prepend('<div class="loading text-center"><img src="/media/img/loading-ajax.gif" alt="Carregant..."></div>');

            var calendar = $('body.inscdonacionshospitals form.reserva .calendari').parent('div');
            var hospital = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').val();
            var now = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').data('now');
            var hospitalText = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"] option:selected').text();
            $.get( "../calendar.php", { hospital: hospital, dia: now}, function(data) {
                $('#hospital_day').val('');
                $('#hora').val('');
                calendar.html(data);
                $("#pas-hospital").addClass('completed');
                $("body.inscdonacionshospitals form.reserva .loading").remove();
                $(".detall").show();
                $("#detall-hospital").html(hospitalText);
                $("body.inscdonacionshospitals form.reserva .info-dia").hide();
            });
        }


        $('body.inscdonacionshospitals form.reserva select[name="hospital"]').on('change', function(event){
            event.preventDefault();

            $("body.inscdonacionshospitals form.reserva").prepend('<div class="loading text-center"><img src="/media/img/loading-ajax.gif" alt="Carregant..."></div>');

            var calendar = $('body.inscdonacionshospitals form.reserva .calendari').parent('div');
            var hospital = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').val();
            var now = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').data('now');
            var hospitalText = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"] option:selected').text();
            $.get( "../calendar.php", { hospital: hospital, dia: now}, function(data) {
                $('#hospital_day').val('');
                $('#hora').val('');
                calendar.html(data);
                $("#pas-hospital").addClass('completed');
                $("body.inscdonacionshospitals form.reserva .loading").remove();
                $(".detall").show();
                $("#detall-hospital").html(hospitalText);
                $("body.inscdonacionshospitals form.reserva .info-dia").hide();
            });
            return false;
        });
        $('body.inscdonacionshospitals form.reserva').on('click', '.calendari thead a', function(event){
            event.preventDefault();

            $("body.inscdonacionshospitals form.reserva").prepend('<div class="loading text-center"><img src="/media/img/loading-ajax.gif" alt="Carregant..."></div>');

            var calendar = $(this).closest('div');
            var month = $(this).data('month');
            var day = $('#hospital_day').val();
            var now = $(this).data('now');
            var hospital = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').val();
            /*var horaSelect = $('#hora .opcions');
            horaSelect.html('<div class="info">Selecciona una data per veure les franjes horàries disponibles</div>');*/
            $.get( "../calendar.php", { hospital: hospital, month: month, day: day, dia: now }, function(data) {
                calendar.html(data);                
                $("body.inscdonacionshospitals form.reserva .loading").remove();
            });
            return false;
        });
        $('body.inscdonacionshospitals form.reserva').on('click', '.calendari tbody a', function(event){
            event.preventDefault();

            $("body.inscdonacionshospitals form.reserva #hora").prepend('<div class="loading"><img src="/media/img/loading-ajax.gif" width="25" alt="Carregant..."></div>');

            var horaSelect = $('#hora .opcions');
            horaSelect.html('');
            var hospital = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').val();
            var day = $(this).data('time');
            //$(this).closest('tbody').find('a').removeClass('cal--selected');
            $('table.calendari a').removeClass('cal--selected');
            $(this).addClass('cal--selected');
            $('#hospital_day').val($(this).data('time')).trigger('change');
            $.get( "../", { hospital: hospital, day: day }, function(data) {
                horaSelect.append(data);
                $("body.inscdonacionshospitals form.reserva #hora .info").remove();
                $("body.inscdonacionshospitals form.reserva #hora .loading").remove();
                if ($('input:radio[name="hour"]').is(':checked')){
                  $("#pas-hora").addClass('completed');
                }else{
                   $("#pas-hora").removeClass('completed');
                }
                $("#detall-dia").html(day);
                //inscSubmit();
            });
            return false;
        });
        $('body.inscdonacionshospitals form.reserva').on('click', ".opcions input", function() {
          $("#pas-hora").addClass('completed');
          $('.opcions label').removeClass('selected');
          $(this).parent().addClass('selected');
          //comprovar disponible
          var disponible = $(this).attr("data-disponible");
          /*var acompanyants = parseInt($('#acompanyants').val()) + 1;
          if (acompanyants > disponible){
              $(this).prop('checked', false);
              $('.opcions label').removeClass('selected');
              $.magnificPopup.open({
                items: {
                  src: '<div class="white-popup">' + $('#acompanyants').attr("data-error") + '</div>',
                  type: 'inline'
                }
              });
          }*/

          $("#detall-hora").html($(this).data("text"));
        });
         /*$('#acompanyants').on('change', function() {
          if($('input[name="hour"]').is(':checked')) {
             var disponible = $('input[name="hour"]:checked').attr("data-disponible");
             var acompanyants = parseInt($(this).val()) + 1;

             if (acompanyants > disponible){
                  $.magnificPopup.open({
                    items: {
                      src: '<div class="white-popup">' + $(this).attr("data-error") + '</div>',
                      type: 'inline'
                    }
                  });
                  $(this).val("0");
             }
          }
        });*/
          
        $('body.inscdonacionshospitals form.reserva').on('change', ".text-check", function() {
          var empty = $(this).parent().find("input.text-check").filter(function() {
              return this.value === "";
          });
          if(!empty.length) {
             $("#pas-dades").addClass('completed');
          }else{
            $("#pas-dades").removeClass('completed');
          }

        });
        /*$('body.inscdonacions form.hospital').on('change', '#hospital_hour', function(event){
            inscSubmit();
            return false;
        });*/
    }



   



    $(".reserves .avis").mouseenter(function(event){
      var offset = $(this).offset();
      if (!$('.avis-reserva-donar-sang').hasClass('active')){
        $('.avis-reserva-donar-sang').css({left:  offset.left, top: offset.top });
      }
      $('.avis-reserva-donar-sang').addClass('active');

    });
    $('.reserves .avis').mouseleave(function() {
        $('.avis-reserva-donar-sang').removeClass('active');
    });
   
    $("#boto-calendari").on("click", function(e) {
        e.preventDefault();
        if ($('.calendari-opcions').hasClass('selected')){
          $('.calendari-opcions').removeClass('selected');
          $('.calendari-opcions').attr('aria-hidden', 'true');
        }else{
          $('.calendari-opcions').addClass('selected');
          $('.calendari-opcions').attr('aria-hidden', 'false');
        }
    });
    $("body").on("click", function(e){
      if(e.target.id == "boto-calendari")
          return;
      if ($('.calendari-opcions').hasClass('selected')){
        $('.calendari-opcions').removeClass('selected');
        $('.calendari-opcions').attr('aria-hidden', 'true');
      }
    });


  


  


});
